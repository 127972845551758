<template>
  <a
    id="show-modal"
    class="btn casino-btn casino-btn-main-menu pull-left casino-main-menu-btn csn-nav-btn csn-nav-btn-main-menu casino-btn-theme-based"
    @click="handleClick"
  >
    <span class="casino-icon casino-icon-navigation csn-nav-btn-icon"></span>
    <span class="csn-nav-btn-label">{{ t('menu') }}</span>
  </a>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import {
  NAVBAR__MAIN_MENU_BUTTON,
  Module,
  TOGGLE_MAIN_MENU,
  TOGGLE_SEARCH_MENU,
  SHOWS_SEARCH_MENU,
} from '@/constants'

export default {
  name: NAVBAR__MAIN_MENU_BUTTON,
  computed: {
    ...mapState(Module.MAIN, [SHOWS_SEARCH_MENU]),
    t() {
      return this.$createComponentTranslator(NAVBAR__MAIN_MENU_BUTTON)
    },
  },
  methods: {
    ...mapMutations(Module.MAIN, { TOGGLE_SEARCH_MENU, TOGGLE_MAIN_MENU }),
    handleClick() {
      this.SHOWS_SEARCH_MENU && this.TOGGLE_SEARCH_MENU(false)
      this.TOGGLE_MAIN_MENU()
    },
  },
}
</script>
